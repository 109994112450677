import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Spinner,
  Row,
  Col,
} from "reactstrap";
import AlertPopup from "../../components/AlertPopup";
import { setErrorMessage } from "../../components/Functions";

import { ApiAuth } from "../../services/auth/ApiAuth";

const ForgotPassword = (props) => {
  console.log("ForgotPassword props: ", props);

  let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const [input, setInput] = useState({
    email: "",
  });
  console.log("ForgotPassword input: ", input);

  let defaultInvalid = {
    email: {
      status: false,
      error: "",
    },
  };

  const [alert, setAlert] = useState({});
  const [isSending, setSending] = useState(false);
  const [invalid, setInvalid] = useState(defaultInvalid);
  console.log("ForgotPassword invalid: ", invalid);

  async function callForgotPassword() {
    await ApiAuth.forgotPassword(input)
      .then((response) => {
        setAlert({
          color: "success",
          visible: true,
          message: response.data.message,
        });
      })
      .catch((error) => {
        setErrorMessage(error, setAlert, setSending);
      });
  }

  async function validateInput(e) {
    setSending(true);
    e.preventDefault();

    if (input.email === "") {
      setInvalid({
        ...invalid,
        email: {
          status: true,
          error: "Please insert your email",
        },
      });
      return;
    } else if (input.email.match(mailFormat) === null) {
      setInvalid({
        ...invalid,
        email: {
          status: true,
          error: "Email is invalid",
        },
      });
      return;
    }

    setInvalid(defaultInvalid);

    // if form is valid, proceed call API
    await callForgotPassword();
    setSending(false);
  }

  function handleChange(e) {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  }

  const AlertBanner = () => (
    <Row>
      <Col>
        <AlertPopup
          color={alert.color || "danger"}
          visible={alert.visible || false}
          onDismiss={() => setAlert({})}
          message={alert.message || ""}
        />
      </Col>
    </Row>
  );

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h1 className="h2">Forgot password</h1>
        <p className="lead">Enter your email to reset your password.</p>
      </div>

      <Card>
        <CardBody>
          <div className="m-sm-4">
            {AlertBanner()}
            <Form onSubmit={validateInput}>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  bsSize="lg"
                  type="text"
                  name="email"
                  placeholder="Enter your email"
                  onChange={handleChange}
                  invalid={invalid.email.status}
                />

                <FormFeedback>{invalid.email.error}</FormFeedback>
              </FormGroup>
              <div className="text-center mt-3">
                <Button type="submit" color="primary" size="lg">
                  {isSending ? <Spinner className="mr-2" size={"sm"} /> : null}{" "}
                  Send
                </Button>
                <Link to="/">
                  <Button color="link" size="lg">
                    Cancel
                  </Button>
                </Link>
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ForgotPassword;
