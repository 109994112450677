import React from "react";

import { Col, Container, Row } from "reactstrap";

import Main from "../components/Main";
import Settings from "../components/Settings";
import image from "../assets/img/main_img.svg";
import logo from "../assets/img/logo.svg";

const Auth = ({ children }) => (
  <React.Fragment>
    <Main className="">
      <div className="container-fluid ">
        <Row className="vh-100">
          <Col
            sm="12"
            md="5"
            lg="5"
            className="d-none d-lg-block"
          >
            <div className="d-flex flex-column bg-gray-600 h-100"> 
            <a className="sidebar-brand" href="/">
              <img
                src={logo}
                alt=""
                className="align-middle text-primary"
                width={"24px"}
              />{" "}
              <span className="align-middle">IMS</span>
            </a>
            <div className="m-auto">
              <div className="text-center mt-5">
                <img className="mb-5" src={image} alt="" width="250px" />
                <h4 className="mb-3">Manage your inventory</h4>
                <p className="px-5 text-muted">
                  Manage and track your organization inventory.
                </p>
              </div>
            </div>
            </div>
          </Col>
          <Col
            sm="12"
            md="7"
            lg="7"
            className="d-flex flex-row align-items-center bg-white"
          >
            <div className="mx-auto px-4">{children}</div>
          </Col>
        </Row>
      </div>
    </Main>
  </React.Fragment>
);

export default Auth;
