import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  FormFeedback,
  FormText,
  Label,
  Input
} from "reactstrap";

import { useQuery } from "../../components/useQuery";
import { ApiAuth } from "../../services/auth/ApiAuth";
import { showToastr } from "../../components/Toast";

const ResetPassword = (props) => {

  console.log('ResetPassword props: ', props)

  let invalidMessage = {
    invalid_format: 'Email format is not valid',
    mismatch_password: 'Confirm password not match',
    empty_password: 'Please insert your new password!',
    empty_confirm_password: 'Please insert the confirmation new password!',
  }

  let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

  const [input, setInput] = useState({
    token: useQuery().get('token') || '' ,
    email: '',
    password: '',
    password_confirmation: ''
  })
  console.log('ResetPassword input: ', input)

  const [invalid, setInvalid] = useState({
    email:{
      status:false,
      error:''
    },
    password:{
      status:false,
      error:''
    },
    password_confirmation:{
      status:false,
      error:''
    }
  })
  console.log('ResetPassword invalid: ', invalid)
  // const [invalid, setInvalid] = useState(['invalid_format', 'mismatch_password'])

  function validateInput(e) {
    // console.log('ResetPassword validateInput e: ', e)
    e.preventDefault();    

    // console.log('ResetPassword validateInput input email match: ', input.email.match(mailFormat))

    if (input.email.match(mailFormat) == null){
      setInvalid({
        ...invalid,
        email:{
          status: true,
          error: 'Invalid email format'
        }
      })
      return;
    }      
    
    if (input.password === ''){
      setInvalid({
        ...invalid,
        password:{
          status: true,
          error: 'Password cant\'t be empty!'
        }
      })
      return;
    }      
    
    if (input.password_confirmation === ''){
      setInvalid({
        ...invalid,
        password:{
          status: true,
          error: 'Password confirmation cant\'t be empty!'
        }
      })
      return;
    }      
    else
    if (input.password_confirmation !== input.password){
      setInvalid({
        password_confirmation:{
          status:true,
          error: 'Password confirmation didn\'t matched'
        }
      })
      return;
    }  

    // if form is validated
    callResetPassword()
  }

  function handleChange(e) {
    setInput({
      ...input,
      [e.target.name]: e.target.value
    })
  }  

  function callResetPassword(){

    ApiAuth.resetPassword(input)
    .then(response => {
      showToastr('Reset password success!', 'Password had been reset successfully.', 'success')
      props.history.push('/')
    })
    .catch(error => {
      // alert(error.response.data.message)            
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        showToastr('Reset password failed!', error.response.data.message[0] || '', 'error')
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);
    })
  }  

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h1 className="h2">Reset password</h1>
      </div>

      <Card>
        <CardBody>
          <div className="m-sm-4">
            <Form onSubmit={validateInput}>
              <FormGroup>
                <Label for="email">
                  Email
                </Label>
                <Input type="text" name="email" onChange={handleChange} invalid={invalid.email.status} value={input.email} placeholder="Enter your email" />
                <FormFeedback>
                  {invalid.email.error}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="password">New Password</Label>
                <Input
                  bsSize="lg"
                  type="password"
                  name="password"
                  placeholder="Enter your new password"
                  onChange={handleChange}
                  value={input.password}
                  invalid={invalid.password.status}
                />
                
                <FormFeedback>{invalid.password.error}</FormFeedback>

              </FormGroup>
              <FormGroup>
                <Label for="password_confirmation">Confirm New Password</Label>
                <Input
                  bsSize="lg"
                  type="password"
                  name="password_confirmation"
                  placeholder="Re-enter your new password"
                  onChange={handleChange}
                  invalid={invalid.password_confirmation.status}
                  value={input.password_confirmation}
                />
                
                <FormFeedback>{ invalid.password_confirmation.error }</FormFeedback>
                
              </FormGroup>
              <div className="text-center mt-3">
                <Button type="submit" color="primary" size="lg">
                  Reset
                </Button>
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
};

export default ResetPassword;
