import { Api } from "../../api/Api.js";

export const ApiAuth = {
  login: function (data) {
    return Api().post("/api/auth/login", data);
  },
  forgotPassword: function (data) {
    return Api().post("/api/password/forgot-password", data);
  },
  resetPassword: function (data) {
    return Api().post("/api/password/reset", data);
  },
};
