import {AUTH_API_URL} from "../api/UserAPI" ;
import { Api } from "../api/Api.js";
import { showToastr } from "../components/Toast";
import axios from "axios";

const register = (name, email, password, confirm_password) => {
    return axios.post(AUTH_API_URL.register, {
      name,
      email,
      password,
      confirm_password
    }).then((response) => {
      if (response.data.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
      }
      return response.data;
    });
};

const login = (email, password) => {
    return axios
      .post(`${process.env.REACT_APP_API_HOSTNAME}/api/auth/login`, {
        email,
        password,
      })
      .then((response) => {
        if (response.data.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
        }
        return response.data;
      });
  };
  
const logout = () => {
    Api().post("/api/auth/logout").then(
      (response)=>{
        showToastr("Logged Out", "Logged out successfully", "success");
        localStorage.removeItem("user");
        window.location.reload();
      }
    ).catch((error) => {
      showToastr("Error", error.message, "error");
    })
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

export default {
    register,
    login,
    logout,
    getCurrentUser,
  };