import React, {useState} from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner
} from "reactstrap";

import AuthService from "../../auth/Auth"

const SignUp = () => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [successful, setSuccessful] = useState(false);


  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangeConfirmPassword = (e) => {
    const confirm_password = e.target.value;
    setConfirmPassword(confirm_password);
  };

  const handleSignUp = () => {

    setMessage("");
    setLoading(true);
    setIsError(false);
    setSuccessful(false);

      AuthService.register(name, email, password, confirmPassword).then(
        (response) => {
          setSuccessful(true);
          setMessage("Account has been created. Now logging you in..")
          window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
          setIsError(true);
          setSuccessful(false);
        }
      );

  };

  return(
  <React.Fragment>
    <div className="text-center mt-4">
      <h1 className="h2">Get started</h1>
      <p className="lead">
        Start creating the best possible user experience for you customers.
      </p>
    </div>
    {isError ? 
          <div className="bg-danger rounded p-3 mb-3">
        <h5 className="text-white text-center m-0">{message}</h5>
        </div> 
        : null}

{successful ? 
          <div className="bg-success rounded p-3 mb-3">
        <h5 className="text-white text-center m-0">{message}</h5>
        </div> 
        : null}

    <Card>
      <CardBody>
        <div className="m-sm-4">
          <Form>
            <FormGroup>
              <Label>Name</Label>
              <Input
                bsSize="lg"
                type="text"
                name="name"
                placeholder="Enter your name"
                onChange={onChangeName}
                disabled={loading}
              />
            </FormGroup>
            <FormGroup>
              <Label>Email</Label>
              <Input
                bsSize="lg"
                type="email"
                name="email"
                placeholder="Enter your email"
                onChange={onChangeEmail}
                disabled={loading}
              />
            </FormGroup>
            <FormGroup>
              <Label>Password</Label>
              <Input
                bsSize="lg"
                type="password"
                name="password"
                placeholder="Enter password"
                onChange={onChangePassword}
                disabled={loading}
              />
            </FormGroup>
            <FormGroup>
              <Label>Confirm Password</Label>
              <Input
                bsSize="lg"
                type="password"
                name="confirm_password"
                placeholder="Re-enter password"
                onChange={onChangeConfirmPassword}
                disabled={loading}
              />
            </FormGroup>
           
          </Form>
          <div className="text-center mt-3">
          <Button color="primary" size="lg" onClick={()=>handleSignUp()} disabled={loading} block>
          {loading ? <Spinner className="mr-2" size={'sm'}/> : null} Sign Up
         </Button>
            </div>
        </div>
      </CardBody>
    </Card>
  </React.Fragment>
)};

export default SignUp;
