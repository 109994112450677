import { USER_GATEWAY } from "./config";

export const AUTH_API_URL = {
  login: USER_GATEWAY + "/auth/login",
  register: USER_GATEWAY + "/auth/register",
};

export const ITEMS_API_URL = {
  dashboard: USER_GATEWAY + "/items/dashboard-numbers",
  allitems: USER_GATEWAY + "/items/all",
};
