import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import {
  //landing as landingRoutes,
  dashboard as dashboardRoutes,
  page as pageRoutes,
} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";
import SignIn from "../pages/auth/SignIn";

import ScrollToTop from "../components/ScrollToTop";
import { checkAuthentication, checkPermission } from "../components/Functions";
import Page403 from "../pages/auth/Page403";

const childRoutes = (Layout, routes) => {
  return routes.map(
    (
      { children, path, component: Component, needAuth, permissions },
      index
    ) => {
      return children ? (
        // Route item with children
        children.map(
          ({ path, component: Component, needAuth, permissions }, index) => (
            <Route
              key={index}
              path={path}
              exact
              render={(props) => {
                if (needAuth) {
                  if (checkAuthentication()) {
                    if (checkPermission(permissions)) {
                      return (
                        <Layout>
                          <Component {...props} />
                        </Layout>
                      );
                    }
                  }
                }

                return <Redirect to="/" />;
              }}
            />
          )
        )
      ) : (
        // Route item without children
        <Route
          key={index}
          path={path}
          exact
          render={(props) => {
            if (needAuth) {
              if (checkAuthentication()) {
                if (checkPermission(permissions)) {
                  return (
                    <Layout>
                      <Component {...props} />
                    </Layout>
                  );
                }
              }
            }

            return <Redirect to="/" />;
          }}
        />
      );
    }
  );
};

const Routes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(checkAuthentication);

  console.log("authenticated: ", isAuthenticated);

  if (!isAuthenticated) {
    return (
      <Router>
        <ScrollToTop>
          <Switch>
            {childRoutes(AuthLayout, pageRoutes)}
            <Route
              key={"login"}
              path={"/"}
              exact
              render={() => (
                <AuthLayout>
                  <SignIn />
                </AuthLayout>
              )}
            />
            <Route render={() => <Redirect to={"/"} />} />
          </Switch>
        </ScrollToTop>
      </Router>
    );
  } else {
    return (
      <Router>
        <ScrollToTop>
          <Switch>
            {childRoutes(DashboardLayout, dashboardRoutes)}
            <Route render={() => <Redirect to={"/dashboard/default"} />} />
          </Switch>
        </ScrollToTop>
      </Router>
    );
  }
};

export default Routes;
