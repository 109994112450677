import React from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import AuthService from "../auth/Auth";

import { Badge, Collapse } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import { Box } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faUser } from "@fortawesome/free-solid-svg-icons";

import routes from "../routes/index";
import avatar from "../assets/img/avatars/avatar.jpg";
import logo from "../assets/img/logo.svg";

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon: Icon,
    isOpen,
    children,
    onClick,
    location,
    to,
  }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === "/" && path === "/dashboard")
        ? "active"
        : "";
    };

    return (
      /*<li className={"sidebar-item " + getSidebarItemClass(to)}>*/
      <li className={"sidebar-item "}>
        <span
          data-toggle="collapse"
          className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
          <Icon size={18} className="align-middle mr-3" />
          <span className="align-middle">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul
            id="item"
            className={"sidebar-dropdown sidebar-collapse list-unstyled"}
          >
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon: Icon, location, to }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname === path ? "active" : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <NavLink to={to} className="sidebar-link" activeClassName="active">
          {Icon ? <Icon size={18} className="align-middle mr-3" /> : null}
          {name}
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggle = (index) => {
    // Collapse all elements
    /*Object.keys(this.state).forEach(
      (item) =>
        this.state[index] ||
        this.setState(() => ({
          [item]: false,
        }))
    );*/

    // Toggle selected element
    this.setState((state) => ({
      [index]: !state[index],
    }));
  };

  UNSAFE_componentWillMount() {
    /* Open collapse element that matches current url */
    const pathName = this.props.location.pathname;

    routes.forEach((route, index) => {
      //const isActive = pathName.indexOf(route.path) === 0;
      const isActive =
        pathName === route.path ||
        route.children.find((data) => data.path === pathName);
      //console.log("test children ", route.children.find((data) => data.path === pathName) ? true : false)
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/" ? true : false;

      this.setState(() => ({
        //[index]: true,
        [index]: isActive || isOpen || isHome,
      }));
    });
  }

  render() {
    const { sidebar, layout } = this.props;
    let user = JSON.parse(localStorage.getItem("user"));
    let permissions = user.permissions;

    let _routes = [];
    routes.forEach((route) => {
      let _children = [];
      route.children.forEach((child) => {
        let allowed = false;
        if (child.permissions)
          child.permissions.forEach((permission) => {
            if (permissions.find((p) => p == permission)) allowed = true;
          });

        if (allowed) _children.push(child);
      });

      if (_children.length > 0)
        _routes.push({
          ...route,
          children: _children,
        });
    });

    return (
      <nav
        className={
          "sidebar" +
          (!sidebar.isOpen ? " toggled" : "") +
          //(sidebar.isSticky ? " sidebar-sticky" : "")
          " sidebar-sticky"
        }
      >
        <div className="sidebar-content">
          <PerfectScrollbar>
            <a className="sidebar-brand" href="/">
              <img
                src={logo}
                alt=""
                className="align-middle text-primary"
                width={"24px"}
              />{" "}
              <span className="align-middle">IMS</span>
            </a>

            <ul className="sidebar-nav mb-5">
              {_routes.map((category, index) => {
                return (
                  <React.Fragment key={index}>
                    {category.header ? (
                      <li className="sidebar-header">{category.header}</li>
                    ) : null}

                    {category.children ? (
                      <SidebarCategory
                        name={category.name}
                        badgeColor={category.badgeColor}
                        badgeText={category.badgeText}
                        icon={category.icon}
                        to={category.path}
                        isOpen={this.state[index]}
                        onClick={() => this.toggle(index)}
                      >
                        {category.children.map((route, index) => (
                          <SidebarItem
                            key={index}
                            name={route.name}
                            to={route.path}
                            badgeColor={route.badgeColor}
                            badgeText={route.badgeText}
                          />
                        ))}
                      </SidebarCategory>
                    ) : (
                      <SidebarItem
                        name={category.name}
                        to={category.path}
                        icon={category.icon}
                        badgeColor={category.badgeColor}
                        badgeText={category.badgeText}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </ul>

            {!layout.isBoxed && !sidebar.isSticky ? (
              <div className="sidebar-bottom d-none d-lg-block">
                <div className="media">
                  <FontAwesomeIcon icon={faUser} className="mr-3" size={"2x"} />
                  <div className="media-body">
                    <h5 className="mb-1">
                      {AuthService.getCurrentUser().user.name}
                    </h5>
                  </div>
                </div>
              </div>
            ) : null}
          </PerfectScrollbar>
        </div>
      </nav>
    );
  }
}

export default withRouter(
  connect((store) => ({
    sidebar: store.sidebar,
    layout: store.layout,
  }))(Sidebar)
);
