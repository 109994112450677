import axios from "axios";

export const Api = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  let token = user ? user.token : null;
  // console.log('Api localStorage: ', localStorage.getItem());

  let header = {
    Accept: "application/json",
    Authorization: "Bearer " + token,
  };

  let headerUpload = {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
    Authorization: "Bearer " + token,
  };

  return {
    post: function (path, data) {
      return axios({
        headers: header,
        method: "post",
        url: process.env.REACT_APP_API_HOSTNAME + path,
        data: data,
      });
    },
    get: function (path, params = {}) {
      return axios({
        headers: header,
        method: "get",
        url: process.env.REACT_APP_API_HOSTNAME + path,
        params: params,
      });
    },
    put: function (path, data) {
      return axios({
        headers: header,
        method: "put",
        url: process.env.REACT_APP_API_HOSTNAME + path,
        data: data,
      });
    },
    delete: function (path) {
      return axios({
        headers: header,
        method: "delete",
        url: process.env.REACT_APP_API_HOSTNAME + path,
      });
    },
    upload: function (path, data) {
      return axios({
        headers: headerUpload,
        method: "post",
        url: process.env.REACT_APP_API_HOSTNAME + path,
        data: data,
      });
    },
  };
};
