export function copyList(list) {
  return [...list];
}

export function findWithAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}

function convertSQLDate(param) {
  var year = param.split("-")[0];
  var month = param.split("-")[1];

  var date = param.split("-")[2];

  if (month != null) {
    month = month.split("-")[0];
    var num = parseInt(month);
    num--;
    month = num.toString();
  }

  if (date != null) {
    date = date.split(" ")[0];
    var num = parseInt(date);
    num++;
    date = num.toString();
  }

  return new Date(year, month, date);
}

export function extractDate(param) {
  if (param != null && param != "") {
    if (param.search("-")) {
      var newDate = convertSQLDate(param);
      return newDate.toISOString().substr(0, 10);
    }
    return param.toISOString();
  } else return "";
}

export function verifyPermission(permission) {
  let verified = false;
  let user = JSON.parse(localStorage.getItem("user"));
  let permissions = user.permissions;

  if (permissions.find((p) => p == permission)) verified = true;

  return verified;
}

export function setErrorMessage(
  error,
  setAlert = (data) => console.log("error message:", data),
  setLoading = (data) => {},
  error_message = "Request failed.\n Please retry again later.\n If error persist, please contact the administrator."
) {
  console.log("request error: ", error);

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);

    let message = error.response.data.message || error_message;

    // showToastr("Failed", message, "error");
    setAlert({
      color: "danger",
      visible: true,
      message: message,
    });
    window.scrollTo(0, 0);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error", error.message);
  }
  console.log("error config", error.config);
  setLoading(false);
}

export function checkAuthentication() {
  let user = JSON.parse(localStorage.getItem("user"));
  if (user != null) return true;
  else return false;
}

export function checkPermission(permissions = []) {
  let user = JSON.parse(localStorage.getItem("user"));
  let userPermissions = user.permissions;

  if (permissions.length > 0) {
    return permissions.some((permission) =>
      userPermissions.length > 0 ? userPermissions.includes(permission) : null
    );
  } else return true;
}
