import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Row, Col, Spinner
} from "reactstrap";

import AuthService from "../../auth/Auth"
const SignIn = (props) => {

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [isError, setIsError] = useState(false);

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = () => {

    setMessage("");
    setLoading(true);
    setIsError(false);

    AuthService.login(email, password).then(
      () => {
        window.location.reload();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
        setIsError(true);
      }
    );

  };

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        //console.log("Enter key was pressed. email and pasword: ", email, password);
        //event.preventDefault();
        handleLogin();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [handleLogin]);

  return (
    <Row>
      <Col sm={12} md={12}>
        <div className="text-center mt-4 mb-5">
          <h2>Inventory Management System</h2>
          <p className="lead">Sign in to your account to continue</p>
        </div>


        <div className="text-center mt-3">
          <Link to="/auth/sign-up">
            <Button color="light" size="lg" block>
              Create an Account
            </Button>
          </Link>
          <div className={'d-flex fex-row align-items-center my-3'}>
            <div className="w-100">
              <hr />
            </div>
            <div className="text-muted px-3">
              or
            </div>
            <div className="w-100">
              <hr />
            </div>
          </div>

        </div>
        <div>
          <div className="">
            {isError ?
              <div className="bg-danger rounded p-3 mb-3">
                <h5 className="text-white text-center m-0">{message}</h5>
              </div>
              : null}

            <Form>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  bsSize="lg"
                  type="email"
                  name="email"
                  value={email}
                  placeholder="Enter your email"
                  onChange={onChangeEmail}
                  disabled={loading}
                />
              </FormGroup>
              <FormGroup>
                <Label>Password</Label>
                <Input
                  bsSize="lg"
                  type="password"
                  name="password"
                  value={password}
                  placeholder="Enter your password"
                  onChange={onChangePassword}
                  disabled={loading}
                />
                <small>
                  <Link to="/auth/forgot-password">Forgot password?</Link>
                </small>
              </FormGroup>
            
            </Form>
  <div className="text-center mt-3">

                <Button color="primary" size="lg" onClick={()=>handleLogin()} disabled={loading} block>
                  {loading ? <Spinner className="mr-2" size={'sm'} /> : null} Sign in
                </Button>

              </div>
          </div>
        </div>
      </Col>
    </Row>
  )
};

export default SignIn;
