import async from "../components/Async";

import {
  Sliders as SlidersIcon,
  User,
  Users as UsersIcon,
  ShoppingCart,
  Truck,
  Home,
  Box,
  FileText,
  DollarSign,
  Edit,
  TrendingUp,
} from "react-feather";

// Auth
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

// Misc
import Blank from "../pages/misc/Blank";

// Dashboards
const Analytics = async(() => import("../pages/dashboards/Analytics"));
const SupplierDashboard = async(() => import("../pages/dashboards/Supplier"));
const DashboardSettings = async(() => import("../pages/dashboards/Settings"));

const UserProfile = async(() => import("../pages/userprofile/UserProfile"));
const Items = async(() => import("../pages/items/ItemsList"));

const Inventory = async(() => import("../pages/inventory/index"));
const AdjustmentList = async(() => import("../pages/inventory/AdjustmentList"));
const AddAdjustment = async(() => import("../pages/inventory/NewAdjustment"));
const Adjustment = async(() => import("../pages/inventory/Adjustment"));

const Purchase = async(() => import("../pages/purchase/PurchaseList"));
const AddPurchase = async(() => import("../pages/purchase/NewPurchase"));
const ViewPurchase = async(() => import("../pages/purchase/ViewPurchase"));
const EditPurchase = async(() => import("../pages/purchase/EditPurchase"));

const Investor = async(() => import("../pages/investor/InvestorList"));
const ViewInvestor = async(() => import("../pages/investor/ViewInvestor"));
const EditInvestor = async(() => import("../pages/investor/EditInvestor"));
const ViewInvestmentDetail = async(() =>
  import("../pages/investor/components/InvestmentDetail")
);

const ApprovedPermit = async(() =>
  import("../pages/approvedPermit/ApprovedPermitList")
);
const AddApprovedPermit = async(() =>
  import("../pages/approvedPermit/NewApprovedPermit")
);
const EditApprovedPermit = async(() =>
  import("../pages/approvedPermit/EditApprovedPermit")
);
const ViewApprovedPermit = async(() =>
  import("../pages/approvedPermit/ViewApprovedPermit")
);

const Shipment = async(() => import("../pages/shipment/index"));
const AddShipment = async(() => import("../pages/shipment/NewShipment"));
const EditShipment = async(() => import("../pages/shipment/EditShipment"));
const ViewShipment = async(() => import("../pages/shipment/ViewShipment"));

const Vendor = async(() => import("../pages/vendor/VendorList"));
const AddVendor = async(() => import("../pages/vendor/NewVendor"));
const EditVendor = async(() => import("../pages/vendor/EditVendor"));
const ViewVendor = async(() => import("../pages/vendor/ViewVendor"));

const AddCustomer = async(() => import("../pages/customer/NewCustomer"));
const ListCustomer = async(() => import("../pages/customer/CustomerList"));
const ViewCustomer = async(() => import("../pages/customer/ViewCustomer"));

const ListSale = async(() => import("../pages/sale/SaleList"));
const AddSale = async(() => import("../pages/sale/NewSale"));
const EditSale = async(() => import("../pages/sale/EditSale"));
const ViewSale = async(() => import("../pages/sale/ViewSale"));
const AuditSale = async(() => import("../pages/sale/AuditSale"));

const AddDelivery = async(() => import("../pages/delivery/NewDelivery"));
const ListDelivery = async(() => import("../pages/delivery/DeliveryList"));
const ViewDelivery = async(() => import("../pages/delivery/ViewDelivery"));
const EditDelivery = async(() => import("../pages/delivery/EditDelivery"));

const AddSupplier = async(() => import("../pages/supplier/NewSupplier"));
const ListSupplier = async(() => import("../pages/supplier/SupplierList"));
const ViewSupplier = async(() => import("../pages/supplier/ViewSupplier"));
const EditSupplier = async(() => import("../pages/supplier/EditSupplier"));

const CurrencyUploaderList = async(() =>
  import("../pages/development/CurrencyUploaderList")
);
const LsuUploaderList = async(() =>
  import("../pages/development/LsuUploaderList")
);
const CurrencyHistoryList = async(() =>
  import("../pages/development/CurrencyHistoryList")
);
const LsuHistoryList = async(() =>
  import("../pages/development/LsuHistoryList")
);

const InvestmentManagement = async(() =>
  import("../pages/investment/management")
);
const ViewActivePurchase = async(() =>
  import("../pages/investment/management/ViewActivePurchase")
);
const ReturnOfInvestments = async(() =>
  import("../pages/investment/returnofinvestments")
);

// Routes

const dashboardRoutes = {
  path: "/dashboard",
  name: "Dashboard",
  icon: SlidersIcon,
  children: [
    {
      path: "/dashboard/default",
      name: "Dashboard",
      component: SupplierDashboard,
      needAuth: true,
      permissions: [
        "view_dashboard_price_sugars",
        "view_dashboard_purchases",
        "view_dashboard_approved_permits",
        "view_dashboard_pledge_investors",
        "view_dashboard_sales",
      ],
    },
    /*,
    {
      path: "/dashboard/supplier",
      name: "Supplier",
      component: SupplierDashboard,
      needAuth: true,permissions: ["view_dashboard_supplier"],
    },
    */
  ],
};

const dashboardsettingsRoutes = {
  path: "/dashboard/settings",
  name: "Dashboard Settings",
  component: DashboardSettings,
  needAuth: true,
  permissions: ["view_dashboard_price_sugars"],
};

const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: UsersIcon,
  badgeColor: "secondary",
  badgeText: "Special",
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      needAuth: false,
      component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      needAuth: false,
      component: SignUp,
    },
    {
      path: "/auth/forgot-password",
      name: "Forgot Password",
      needAuth: false,
      component: ForgotPassword,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      needAuth: false,
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      needAuth: false,
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      needAuth: false,
      component: Page500,
    },
  ],
};

const itemRoutes = {
  path: "/items",
  name: "Items",
  icon: ShoppingCart,
  children: [
    {
      path: "/items",
      name: "Items",
      component: Items,
      icon: ShoppingCart,
      needAuth: true,
      permissions: ["view_item"],
    },
    {
      path: "/inventory",
      name: "Inventory",
      component: Inventory,
      icon: FileText,
      needAuth: true,
      permissions: ["view_inventory"],
    },
    {
      path: "/adjustments",
      name: "Inventory Adjustment",
      component: AdjustmentList,
      icon: Edit,
      needAuth: true,
      permissions: ["view_inventory_adjustment"],
    },
  ],
};

const purchaseRoutes = {
  path: "/purchases",
  name: "Purchases",
  icon: FileText,
  children: [
    {
      path: "/purchases",
      name: "Purchase Orders",
      component: Purchase,
      icon: FileText,
      needAuth: true,
      permissions: ["view_purchase"],
    },
    {
      path: "/shipments",
      name: "Shipments",
      component: Shipment,
      icon: Box,
      needAuth: true,
      permissions: ["view_shipment"],
    },
  ],
};

const saleRoutes = {
  path: "/invoices",
  name: "Sales",
  icon: DollarSign,
  children: [
    {
      path: "/invoices",
      name: "Invoices",
      component: ListSale,
      icon: DollarSign,
      needAuth: true,
      permissions: ["view_sale"],
    },
    {
      path: "/customers",
      name: "Customers",
      component: ListCustomer,
      icon: User,
      needAuth: true,
      permissions: ["view_customer"],
    },
    {
      path: "/delivery",
      name: "Delivery Orders",
      component: ListDelivery,
      icon: Truck,
      needAuth: true,
      permissions: ["view_delivery"],
    },
  ],
};

const otherRoutes = {
  path: "/other",
  name: "Other",
  icon: Truck,
  children: [
    {
      path: "/suppliers",
      name: "Suppliers",
      icon: Truck,
      component: ListSupplier,
      needAuth: true,
      permissions: ["view_supplier"],
    },
    {
      path: "/APs",
      name: "Approved Permits",
      component: ApprovedPermit,
      icon: FileText,
      needAuth: true,
      permissions: ["view_approved_permit"],
    },
    {
      path: "/vendors",
      name: "Vendors",
      component: Vendor,
      icon: Home,
      needAuth: true,
      permissions: ["view_vendor"],
    },
  ],
};

const investmentmgmtRoutes = {
  path: "/investment",
  name: "Investment",
  icon: TrendingUp,
  children: [
    {
      path: "/investors",
      name: "Investors",
      component: Investor,
      icon: User,
      needAuth: true,
      permissions: ["view_investor"],
    },
    {
      path: "/investment/management",
      name: "Investment Management",
      component: InvestmentManagement,
      needAuth: true,
      permissions: [
        "view_investment_list",
        "manage_active_purchase",
        "manage_item_depletion",
        "view_closed_purchase",
      ],
    },
  ],
};

//items ->sidebar

const newAdjustmentRoutes = {
  path: "/adjustments/new",
  component: AddAdjustment,
  needAuth: true,
  permissions: ["create_inventory_adjustment"],
};

const adjustmentRoutes = {
  path: "/adjustments/:id",
  component: Adjustment,
  needAuth: true,
  permissions: ["view_inventory_adjustment"],
};

//shipments ->sidebar

const newshipmentRoutes = {
  path: "/shipments/new",
  name: "New Shipment",
  component: AddShipment,
  needAuth: true,
  permissions: ["create_shipment"],
};

const editshipmentRoutes = {
  path: "/shipments/edit/:id",
  name: "Edit Shipment",
  component: EditShipment,
  needAuth: true,
  permissions: ["edit_shipment"],
};

const viewShipmentRoutes = {
  path: "/shipments/:id",
  component: ViewShipment,
  needAuth: true,
  permissions: ["view_shipment"],
};

//purchase ->sidebar
const newPurchaseRoutes = {
  path: "/purchases/new",
  component: AddPurchase,
  needAuth: true,
  permissions: ["create_purchase"],
};

const viewPurchaseRoutes = {
  path: "/purchases/:id",
  component: ViewPurchase,
  needAuth: true,
  permissions: ["view_purchase"],
};

const editPurchaseRoutes = {
  path: "/purchases/edit/:id",
  component: EditPurchase,
  needAuth: true,
  permissions: ["edit_purchase"],
};

const userProfileRoutes = {
  path: "/userprofile",
  name: "User Profile",
  component: UserProfile,
  header: "Account",
  icon: User,
  needAuth: true,
  permissions: [],
};

//supplier ->sidebar

const newSupplierRoutes = {
  path: "/suppliers/new",
  component: AddSupplier,
  needAuth: true,
  permissions: ["create_supplier"],
};

const editSupplierRoutes = {
  path: "/suppliers/edit/:id",
  component: EditSupplier,
  needAuth: true,
  permissions: ["edit_supplier"],
};

const viewSupplierRoutes = {
  path: "/suppliers/:id",
  component: ViewSupplier,
  needAuth: true,
  permissions: ["view_supplier"],
};

//vendor ->sidebar

const newVendorRoutes = {
  path: "/vendors/new",
  component: AddVendor,
  needAuth: true,
  permissions: ["create_vendor"],
};

const editVendorRoutes = {
  path: "/vendors/edit/:id",
  component: EditVendor,
  needAuth: true,
  permissions: ["edit_vendor"],
};

const viewVendorRoutes = {
  path: "/vendors/:id",
  component: ViewVendor,
  needAuth: true,
  permissions: ["view_vendor"],
};

//approved permit ->sidebar

const newApprovedPermitRoutes = {
  path: "/APs/new",
  component: AddApprovedPermit,
  needAuth: true,
  permissions: ["create_approved_permit"],
};

const editApprovedPermitRoutes = {
  path: "/APs/edit/:id",
  component: EditApprovedPermit,
  needAuth: true,
  permissions: ["edit_approved_permit"],
};

const viewApprovedPermitRoutes = {
  path: "/APs/:id",
  component: ViewApprovedPermit,
  needAuth: true,
  permissions: ["view_approved_permit"],
};

//investor permit ->sidebar

const viewInvestorRoutes = {
  path: "/investors/:id",
  component: ViewInvestor,
  needAuth: true,
  permissions: ["view_investor"],
};

const editInvestorRoutes = {
  path: "/investors/edit/:id",
  component: EditInvestor,
  needAuth: true,
  permissions: ["edit_investor"],
};

const viewInvestorInvestmentRoutes = {
  path: "/investors/:id/investments/:purchaseId",
  component: ViewInvestmentDetail,
  needAuth: true,
  permissions: ["view_investment_list"],
};

//customer ->sidebar

const newCustomerRoutes = {
  path: "/customers/new",
  component: AddCustomer,
  needAuth: true,
  permissions: ["create_customer"],
};

const viewCustomerRoutes = {
  path: "/customers/:id",
  component: ViewCustomer,
  needAuth: true,
  permissions: ["view_customer"],
};

//delivery ->sidebar
const newDeliveryRoutes = {
  path: "/delivery/new",
  component: AddDelivery,
  needAuth: true,
  permissions: ["create_delivery"],
};

const viewDeliveryRoutes = {
  path: "/delivery/:id",
  component: ViewDelivery,
  needAuth: true,
  permissions: ["view_delivery"],
};

const editDeliveryRoutes = {
  path: "/delivery/edit/:id",
  component: EditDelivery,
  needAuth: true,
  permissions: ["edit_delivery"],
};

//sales ->sidebar

const viewSaleRoutes = {
  path: "/invoices/:id",
  component: ViewSale,
  needAuth: true,
  permissions: ["view_sale"],
};

const viewAuditSaleRoutes = {
  path: "/invoices/:id/audits",
  component: AuditSale,
  needAuth: true,
  permissions: ["view_sale"],
};

const newSaleRoutes = {
  path: "/invoices/new",
  component: AddSale,
  needAuth: true,
  permissions: ["create_sale"],
};

const editSaleRoutes = {
  path: "/invoices/edit/:id",
  component: EditSale,
  needAuth: true,
  permissions: ["edit_sale"],
};

//development ->sidebar

const currencyUploaderRoutes = {
  path: "/dev/currency-uploader/",
  component: CurrencyUploaderList,
  needAuth: true,
  permissions: ["view_dashboard_price_sugars"],
};

const lsuUploaderRoutes = {
  path: "/dev/lsu-uploader/",
  component: LsuUploaderList,
  needAuth: true,
  permissions: ["view_dashboard_price_sugars"],
};

const currencyListRoutes = {
  path: "/dev/currency-list/",
  component: CurrencyHistoryList,
  needAuth: true,
  permissions: ["view_dashboard_price_sugars"],
};

const lsuListRoutes = {
  path: "/dev/lsu-list/",
  component: LsuHistoryList,
  needAuth: true,
  permissions: ["view_dashboard_price_sugars"],
};

//investment management
//Active Purchase
const viewActivePurchaseRoutes = {
  path: "/activepurchase/:id",
  component: ViewActivePurchase,
  needAuth: true,
  permissions: ["manage_active_purchase", "view_closed_purchase"],
};

//ROI
const viewROIRoutes = {
  path: "/roi/:id",
  component: ReturnOfInvestments,
  needAuth: true,
  permissions: ["calculate_roi"],
};

// This route is not visisble in the sidebar
const privateRoutes = {
  path: "/private",
  name: "Private",
  children: [
    {
      path: "/private/blank",
      name: "Blank Page",
      component: Blank,
    },
  ],
};

// Dashboard specific routes
export const dashboard = [
  dashboardRoutes,
  itemRoutes,
  purchaseRoutes,
  userProfileRoutes,
  otherRoutes,
  privateRoutes,
  newPurchaseRoutes,
  viewPurchaseRoutes,
  editPurchaseRoutes,
  newshipmentRoutes,
  viewShipmentRoutes,
  newApprovedPermitRoutes,
  editApprovedPermitRoutes,
  newCustomerRoutes,
  viewApprovedPermitRoutes,
  viewCustomerRoutes,
  viewInvestorRoutes,
  newDeliveryRoutes,
  viewDeliveryRoutes,
  editDeliveryRoutes,
  saleRoutes,
  newSaleRoutes,
  viewSaleRoutes,
  editSaleRoutes,
  editshipmentRoutes,
  editInvestorRoutes,
  newVendorRoutes,
  editVendorRoutes,
  viewVendorRoutes,
  newSupplierRoutes,
  editSupplierRoutes,
  viewSupplierRoutes,
  newAdjustmentRoutes,
  adjustmentRoutes,
  currencyUploaderRoutes,
  lsuUploaderRoutes,
  currencyListRoutes,
  lsuListRoutes,
  dashboardsettingsRoutes,
  viewAuditSaleRoutes,
  viewInvestorInvestmentRoutes,
  investmentmgmtRoutes,
  viewActivePurchaseRoutes,
  viewROIRoutes,
];

export const purchasing = [
  dashboardRoutes,
  itemRoutes,
  purchaseRoutes,
  userProfileRoutes,
  otherRoutes,
  privateRoutes,
  newPurchaseRoutes,
  viewPurchaseRoutes,
  editPurchaseRoutes,
  newshipmentRoutes,
  viewShipmentRoutes,
  newApprovedPermitRoutes,
  editApprovedPermitRoutes,
  newCustomerRoutes,
  viewApprovedPermitRoutes,
  viewCustomerRoutes,
  viewInvestorRoutes,
  newDeliveryRoutes,
  viewDeliveryRoutes,
  editDeliveryRoutes,
  saleRoutes,
  newSaleRoutes,
  viewSaleRoutes,
  editSaleRoutes,
  editshipmentRoutes,
  editInvestorRoutes,
  newVendorRoutes,
  editVendorRoutes,
  viewVendorRoutes,
  newSupplierRoutes,
  editSupplierRoutes,
  viewSupplierRoutes,
  newAdjustmentRoutes,
  adjustmentRoutes,
  currencyUploaderRoutes,
  lsuUploaderRoutes,
  currencyListRoutes,
  lsuListRoutes,
  dashboardsettingsRoutes,
  viewAuditSaleRoutes,
  viewInvestorInvestmentRoutes,
  investmentmgmtRoutes,
  viewActivePurchaseRoutes,
  viewROIRoutes,
];

// Landing specific routes
//export const landing = [landingRoutes];

// Auth specific routes
export const page = [authRoutes];

// All routes
export default [
  dashboardRoutes,
  itemRoutes,
  purchaseRoutes,
  saleRoutes,
  investmentmgmtRoutes,
  otherRoutes,
];

export const stockNavBar = [
  dashboardRoutes,
  itemRoutes,
  purchaseRoutes,
  saleRoutes,
  investmentmgmtRoutes,
  otherRoutes,
];
