import { faBell } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Alert } from "reactstrap";

const AlertPopup = ({
  color = "info",
  visible = false,
  onDismiss,
  message = "",
}) => {
  function newlineText() {
    const text = message;
    const newText = text
      .split("\n")
      .map((str, index) => <p key={index}>{str}</p>);

    return newText;
  }
  return (
    <Alert color={color} isOpen={visible} toggle={onDismiss}>
      <div className="alert-icon">
        <FontAwesomeIcon icon={faBell} fixedWidth />
      </div>
      <div className="alert-message">{newlineText()}</div>
    </Alert>
  );
};

export default AlertPopup;
