import { toastr } from "react-redux-toastr";

export const showToastr = (
    title = "Title Here.",
    message = "Message Here...",
    type = "info",
    timeOut = 5000,
    showCloseButton = true,
    progressBar = true,
    position = "bottom-right"
) => {
    const options = {
        timeOut: parseInt(timeOut),
        showCloseButton: showCloseButton,
        progressBar: progressBar,
        position: position
    };

    const toastrInstance =
        type === "info"
            ? toastr.info
            : type === "warning"
            ? toastr.warning
            : type === "error"
            ? toastr.error
            : toastr.success;

    return toastrInstance(title, message, options);
};
